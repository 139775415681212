.stars0s {
  width: 34px;
  height: 24px;
  background-image: url('../../../icons/Pin with Stars_blue_No-rating.png');
  background-size: 34px 24px;
}
.stars0m {
  width: 69px;
  height: 48px;
  background-image: url('../../../icons/Pin with Stars_blue_No-rating.png');
  background-size: 69px 48px;
}
.stars0l {
  width: 86px;
  height: 60px;
  background-image: url('../../../icons/Pin with Stars_blue_No-rating.png');
  background-size: 86px 60px;
}

.stars1s {
  width: 34px;
  height: 24px;
  background-image: url('../../../icons/Pin with Stars_1_Blue.png');
  background-size: 34px 24px;
}
.stars1m {
  width: 69px;
  height: 48px;
  background-image: url('../../../icons/Pin with Stars_1_Blue.png');
  background-size: 69px 48px;
}
.stars1l {
  width: 86px;
  height: 60px;
  background-image: url('../../../icons/Pin with Stars_1_Blue.png');
  background-size: 86px 60px;
}

.stars2s {
  width: 34px;
  height: 24px;
  background-image: url('../../../icons/Pin with Stars_2_Blue.png');
  background-size: 34px 24px;
}
.stars2m {
  width: 69px;
  height: 48px;
  background-image: url('../../../icons/Pin with Stars_2_Blue.png');
  background-size: 69px 48px;
}
.stars2l {
  width: 86px;
  height: 60px;
  background-image: url('../../../icons/Pin with Stars_2_Blue.png');
  background-size: 86px 60px;
}

.stars3s {
  width: 34px;
  height: 24px;
  background-image: url('../../../icons/Pin with Stars_3_Blue.png');
  background-size: 34px 24px;
}
.stars3m {
  width: 69px;
  height: 48px;
  background-image: url('../../../icons/Pin with Stars_3_Blue.png');
  background-size: 69px 48px;
}
.stars3l {
  width: 86px;
  height: 60px;
  background-image: url('../../../icons/Pin with Stars_3_Blue.png');
  background-size: 86px 60px;
}

.stars4s {
  width: 34px;
  height: 24px;
  background-image: url('../../../icons/Pin with Stars_4_Blue.png');
  background-size: 34px 24px;
}
.stars4m {
  width: 69px;
  height: 48px;
  background-image: url('../../../icons/Pin with Stars_4_Blue.png');
  background-size: 69px 48px;
}
.stars4l {
  width: 86px;
  height: 60px;
  background-image: url('../../../icons/Pin with Stars_4_Blue.png');
  background-size: 86px 60px;
}

.stars5s {
  width: 34px;
  height: 24px;
  background-image: url('../../../icons/Pin with Stars_5_Blue.png');
  background-size: 34px 24px;
}
.stars5m {
  width: 69px;
  height: 48px;
  background-image: url('../../../icons/Pin with Stars_5_Blue.png');
  background-size: 69px 48px;
}
.stars5l {
  width: 86px;
  height: 60px;
  background-image: url('../../../icons/Pin with Stars_5_Blue.png');
  background-size: 86px 60px;
}

.stars1map {
  width: 46px;
  height: 10px;
  background-image: url('../../../icons/Stars_1.svg');
  background-size: 46px 10px;
}

.stars2map {
  width: 46px;
  height: 10px;
  background-image: url('../../../icons/Stars_2.svg');
  background-size: 46px 10px;
}

.stars3map {
  width: 46px;
  height: 10px;
  background-image: url('../../../icons/Stars_3.svg');
  background-size: 46px 10px;
}

.stars4map {
  width: 46px;
  height: 10px;
  background-image: url('../../../icons/Stars_4.svg');
  background-size: 46px 10px;
}

.stars5map {
  width: 46px;
  height: 10px;
  background-image: url('../../../icons/Stars_5.svg');
  background-size: 46px 10px;
}

.ttCode-container {
  width: 100%;
  color: white;
  background-color: #0059a7;
  border-color:  #0059a7;
}


.hover-block {
  width: 600px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: white;
  background-color: #0059a7;
  border-color:  #0059a7;
}

.hover-block2 {
  overflow-wrap: break-work;
  color: white;
  background-color: #0059a7;
  border-color:  #0059a7;
}

.text-block {
  width: 600px;
  color: black;
  background-color: #e0e0e0;
  border-color:  #e0e0e0;
}

.text-block2 {
  color: black;
  background-color: #e0e0e0;
  border-color:  #e0e0e0;
}

.ttCode {
  z-index: 99999;
  width: 100%;
  border: 1px solid;
  color: #0059a7;
  background-color: #e0e0e0;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.right-align {
  margin-left: auto;
  margin-right: 0;
}