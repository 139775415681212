.selectedpayer {
  width: 28px;
  height: 28px;
  margin: auto;
  background-size: 100%;
  block-size: 28px;
  inline-size: 28px;
}


.greencell {
  text-align: right;
  background-color: #EDFCF2;
  font-feature-settings: "tnum" on;
} 

.yellowcell {
  text-align: right;
  background-color: #FEFDF0;
  font-feature-settings: "tnum" on;
} 

.redcell {
  text-align: right;
  background-color: #FEF3F2;
  font-feature-settings: "tnum" on;
}

.nocolorcell {
  text-align: right;
  font-feature-settings: "tnum" on;
}

/* Show placeholders in italics */
input:placeholder-shown {
  font-style: italic;
}

/* From pricetransparency: style report an issue as a button */
.padding-xsmall {
  padding: .5rem;
}

.details-report-issue-form-wrapper {
  justify-content: center;
  display: flex;
}

.button-report-issue-form {
  border: .5px solid var(--555555);
  color: var(--555555);
  background-color: #fff;
  font-size: .75rem;
  font-weight: 300;
}

/* Applied to div around a label to show it disabled */
.disabled label {
  color: #ccc;
  pointer-events: none;
}

/* For div surrounding the grid */
.grid-container {
  min-width: 0;
  min-height: 0;
}
