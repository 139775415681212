@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.header-display-desktop {
  background-color: var(--body-bg-primary);
}

/*
.footer-wrapper {
  width: 100%;
  max-width: none;
  background-color: var(--pra-blue);
  justify-content: center;
  margin-top: auto;
  padding: 1vmax 0 1vmax 17px;
  display: flex;
  position: relative;
}

.footer-content {
  width: 100%;
}

.footer-layout {
  width: auto;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: .75fr .25fr 1fr 1fr .75fr 1fr 1fr .5fr .5fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: grid;
}

.image {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  display: inline-block;
}

.image.logo-footer-white {
  width: 184px;
  height: 46px;
}

.section-footer {
  margin-top: auto;
}

.footer-link {
  color: #fff;
  white-space: normal;
  word-break: normal;
  padding-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  text-decoration: underline;
}

.footer-link-block {
  padding-left: 17px;
  padding-right: 17px;
}

.footer-social-media-icons-wrapper {
  color: #000;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.footer-social-media-icon-link-block {
  margin-left: 20px;
  display: block;
}

.btn-footer-subscribe-for-updates-wrapper {
  justify-content: center;
  padding-right: 0;
  display: flex;
  position: relative;
}

.button-2 {
  background-color: var(--pra-red);
  text-align: center;
  font-size: 21px;
  font-weight: 500;
}

.footer-copyright {
  padding-top: 17px;
  padding-bottom: 17px;
}

.copyright-text {
  color: #fff;
}

.footer-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.footer-layout {
  grid-template-columns: 1fr;
}

.footer-link {
  font-size: .75rem;
}

.button-2.mobile-subscribe-btn {
  font-size: 16px;
}

.footer-copyright {
  text-align: center;
}

.copyright-text {
  font-size: .75rem;
}






[data-theme='dark'].Theme-wrapper {
  background: #1a1a1a;
  color: #fff;
}
.Theme-wrapper {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  background: #f5f6f7;
  height: 100vh;
  padding-top: 20px;
}
.Theme-toggler {
  display: block;
  margin: auto;
}

.App {
  max-width: 728px;
  margin: 4rem auto;
}

.App > h1 {
  text-align: center;
  margin: 1rem 0;
}

[data-theme='dark'] .Card {
  background: #292828;
  color: #fff;
}

.Card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0.5rem 1rem;
}

.Card:not(:last-child) {
  border-bottom: 1px solid #333333;
}

.Card--text h1 {
  color: #0970b4;
  text-transform: capitalize;
}

.Card--button {
  padding: 0.4rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  background: #00aa69;
  border: 1px solid #00aa69;
  color: #fff;
  margin-right: 1rem;
}

[data-theme='dark'] .Form {
  background: #292828;
  color: #fff;
}

.Form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #fff;
  margin-bottom: 1rem;
}

.Form > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-theme='dark'] .Form input {
  background: #363636;
  color: #fff;
}

.Form input {
  background: #f5f6f7;
  padding: 0.5rem 1rem;
  border: 1px solid #0970b4;
  border-radius: 10px;
  display: block;
  margin: 0.3rem 1rem 0 0;
  outline: none;
}

.Form button {
  background: #099dff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  border: none;
}

.line-through {
  text-decoration: line-through;
  color: #777 !important;
}

.hide-button {
  display: none;
}
*/